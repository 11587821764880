import * as types from '../mutation-types'

export const fetchCampaignShareShips = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchCampaignShareShips')

  return new Promise((resolve, reject) => {
    model
      .fetchCampaignShareShips(options)
      .then((response) => {
        dispatch(
          'promoterCampaignShareShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('promoterShares/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterShareLevels/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('promoterLevels/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('promoters/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterMembers/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterPreferredCategoryShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productCategories/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignPeriodShares/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignPeriods/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignSampleShipments/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchCampaignShareShips')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCampaignShareShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const batchSaveCampaignShareShips = (
  { dispatch, commit },
  { model, promoterShareIds }
) => {
  commit(types.API_REQUEST_START, 'batchSaveCampaignShareShips')

  return new Promise((resolve, reject) => {
    model
      .batchSaveCampaignShareShips({
        promoterShareIds: promoterShareIds
      })
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'batchSaveCampaignShareShips')

        dispatch(
          'promoterCampaignShareShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('promoterShares/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: batchSaveCampaignShareShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productShips
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
